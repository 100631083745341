import * as React from 'react';
import { OfferingImage } from '../../OfferingImage/OfferingImage';
import { ImageViewModel } from '../../../domain/offering-view-model-factory';
import { st, classes } from './OfferingViewImage.st.css';
import {
  ImageShapeOptions,
  OfferingListLayoutOptions,
} from '../../../../Shared/appKeys/SettingsKeys';
import {
  ExperimentsProps,
  withExperimentsContext,
} from '../../../../Shared/context/experiments-context';
import { DEFAULT_IMAGE_CONTAINER } from '../OfferingView.const';

interface OfferingViewImageProps extends ExperimentsProps {
  imageViewModel: ImageViewModel;
  width: number;
  height: number;
  onClick: any;
  forwardedRef: any;
  layout: OfferingListLayoutOptions;
  title: string;
}

class OfferingViewImageComponent extends React.PureComponent<OfferingViewImageProps> {
  render() {
    const {
      imageViewModel,
      height,
      onClick,
      layout,
      forwardedRef,
      title,
      experiments,
    } = this.props;
    const {
      shape,
      imageResize,
      mediaItem,
      focalPoint,
      aspectRatio,
    } = imageViewModel;
    const isRoundGrid =
      shape === ImageShapeOptions.ROUND &&
      layout === OfferingListLayoutOptions.GRID;

    const width = isRoundGrid ? height : this.props.width;
    const innerPadding = isRoundGrid ? 32 : 0;

    return (
      <div className={classes.imageWrapper} ref={forwardedRef}>
        <div
          className={st(classes.root, {
            shape: isRoundGrid ? 'round' : 'square',
          })}
          style={{
            height: isRoundGrid ? `calc(100% - ${innerPadding}px)` : null,
            width: isRoundGrid
              ? `calc(${100 / aspectRatio}% - ${innerPadding}px)`
              : null,
          }}
        >
          <div
            data-hook="offering-view-image"
            onClick={onClick}
            className={classes.clickable}
            role="presentation"
            tabIndex={-1}
            style={{
              height:
                experiments?.isRenderImgElement &&
                height !== DEFAULT_IMAGE_CONTAINER.height
                  ? height
                  : '100%',
              width:
                experiments?.isRenderImgElement &&
                width !== DEFAULT_IMAGE_CONTAINER.width
                  ? width
                  : '100%',
            }}
          >
            <OfferingImage
              width={width}
              height={height}
              aspectRatio={aspectRatio}
              imageResize={imageResize}
              mediaItem={mediaItem}
              focalPoint={focalPoint}
              title={title}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const OfferingViewImage = withExperimentsContext(
  OfferingViewImageComponent,
);
