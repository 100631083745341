import * as React from 'react';
import { Divider } from 'wix-ui-tpa/Divider';
import { classes, st } from './OfferingInfo.st.css';
import { IOfferingViewModel } from '../../domain/offering-view-model-factory';
import { OfferingName } from './OfferingName/OfferingName';
import { OfferingTagLine } from './OfferingTagLine/OfferingTagLine';
import { OfferingDetails } from './OfferingDetails/OfferingDetails';
import { OfferingCTA } from './OfferingCTA/OfferingCTA';
import {
  BiLoggerProps,
  withBiLoggerContext,
} from '../context/bi-logger-context';
import {
  ACTION_NAMES,
  WIDGET_BI_REFERRAL,
} from '../../adapters/reporting/bi-logger/bi.const';
import { OfferingIntent } from '../../platform/navigation/navigation.const';
import {
  OfferingCallbacksProps,
  withOfferingCallbacksContext,
} from '../context/offering-callbacks-context';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../context/running-environment-context';
import { OfferingListLayoutOptions } from '../../../Shared/appKeys/SettingsKeys';
import { OfferingMoreInfoLabel } from './OfferingMoreInfoLabel/OfferingMoreInfoLabel';
import {
  ExperimentsProps,
  withExperimentsContext,
} from '../../../Shared/context/experiments-context';
import { ServiceType } from '@wix/bookings-uou-types';
import { OfferingOnlineIndication } from './OfferingOnlineIndication/OfferingOnlineIndication';

interface OfferingInfoProps extends ExperimentsProps {
  offeringViewModel: IOfferingViewModel;
}

class OfferingInfo extends React.PureComponent<
  OfferingInfoProps &
    BiLoggerProps &
    OfferingCallbacksProps &
    RunningEnvironmentProps
> {
  constructor(props) {
    super(props);
    this.actionButtonClickHandler = this.actionButtonClickHandler.bind(this);
    this.offeringNameClickHandler = this.offeringNameClickHandler.bind(this);
    this.offeringMoreInfoClickHandler = this.offeringMoreInfoClickHandler.bind(
      this,
    );
  }

  actionButtonClickHandler() {
    const { offeringViewModel } = this.props;

    if (
      offeringViewModel.displayOnlyNoBookFlow ||
      offeringViewModel.type === ServiceType.COURSE
    ) {
      this.onOfferingSelected(OfferingIntent.SHOW_DETAILS);
    } else {
      this.onOfferingSelected(OfferingIntent.BOOK_OFFERING);
    }
  }

  offeringNameClickHandler() {
    this.logOfferingNameClicked();
    this.onOfferingSelected(OfferingIntent.SHOW_DETAILS);
  }

  offeringMoreInfoClickHandler() {
    this.logMoreInfoClicked();
    this.onOfferingSelected(OfferingIntent.SHOW_DETAILS);
  }

  logOfferingNameClicked() {
    this.logAction(WIDGET_BI_REFERRAL.WIDGET_TITLE);
  }

  logMoreInfoClicked() {
    this.logAction(WIDGET_BI_REFERRAL.WIDGET_MORE_INFO);
  }

  logActionButtonClicked(actionName?: string) {
    this.logAction(WIDGET_BI_REFERRAL.WIDGET_BUTTON, actionName);
  }

  logAction(referralInfo, actionName?): void {
    const { offeringViewModel, biLoggerDriver } = this.props;
    if (actionName) {
      biLoggerDriver.sendWidgetClick(
        offeringViewModel.id,
        offeringViewModel.type,
        offeringViewModel.isPendingApprovalFlow,
        referralInfo,
        actionName,
      );
    } else {
      biLoggerDriver.sendWidgetClick(
        offeringViewModel.id,
        offeringViewModel.type,
        offeringViewModel.isPendingApprovalFlow,
        referralInfo,
      );
    }
  }

  onOfferingSelected(intent: OfferingIntent) {
    const {
      offeringCallbacks,
      offeringViewModel,
      runningEnvironment,
    } = this.props;

    if (
      intent === OfferingIntent.BOOK_OFFERING &&
      runningEnvironment.isCalendarPageInstalled
    ) {
      this.logActionButtonClicked(ACTION_NAMES.NAVIGATE_TO_CALENDAR);
    } else {
      this.logActionButtonClicked();
    }
    offeringCallbacks.onOfferingSelected(
      offeringViewModel.id,
      intent,
      offeringViewModel.locationId,
    );
  }

  private isStripLayout(): boolean {
    return (
      this.props.offeringViewModel.layout === OfferingListLayoutOptions.STRIP
    );
  }

  private renderHorizontalLayoutInfo() {
    const { offeringViewModel, experiments, runningEnvironment } = this.props;
    const { locale, isSSR } = runningEnvironment;
    return (
      <div
        data-hook="info-container"
        className={st(classes.horizontalLayout, {
          verticalAlignContent: offeringViewModel.verticalAlignInfoContent,
        })}
      >
        <div className={classes.column}>
          {offeringViewModel.onlineIndication.isVisible && (
            <div className={classes.badgeWrapper}>
              <OfferingOnlineIndication data-hook="online-badge">
                {offeringViewModel.onlineIndication.text}
              </OfferingOnlineIndication>
            </div>
          )}
          <OfferingName
            data-hook="tile-title"
            url={offeringViewModel.fullUrl}
            onClick={this.offeringNameClickHandler}
            htmlTag={offeringViewModel.titleHtmlTag}
          >
            {offeringViewModel.title}
          </OfferingName>
          {offeringViewModel.tagLine && (
            <OfferingTagLine
              data-hook="tile-tagline"
              htmlTag={offeringViewModel.tagLineHtmlTag}
            >
              {offeringViewModel.tagLine}
            </OfferingTagLine>
          )}
          {offeringViewModel.moreInfoLabel.isVisible && (
            <OfferingMoreInfoLabel
              onClick={this.offeringMoreInfoClickHandler}
              dataHook="tile-more-info"
            >
              {offeringViewModel.moreInfoLabel.text}
            </OfferingMoreInfoLabel>
          )}
        </div>
        <div className={classes.column}>
          {offeringViewModel.days && (
            <OfferingDetails
              data-hook="tile-days"
              htmlTag={offeringViewModel.detailsHtmlTag}
              ellipsis
            >
              {offeringViewModel.days}
            </OfferingDetails>
          )}
          {offeringViewModel.startDate && (
            <OfferingDetails
              data-hook="tile-start-date"
              htmlTag={offeringViewModel.detailsHtmlTag}
              ellipsis
            >
              {experiments.isUseIntlToFormatCourseDurationEnabled &&
              locale !== 'en' &&
              isSSR ? (
                <>&nbsp;</>
              ) : (
                offeringViewModel.startDate
              )}
            </OfferingDetails>
          )}
          {offeringViewModel.duration && (
            <OfferingDetails
              data-hook="tile-duration"
              htmlTag={offeringViewModel.detailsHtmlTag}
              ellipsis
            >
              {offeringViewModel.duration}
            </OfferingDetails>
          )}
          {offeringViewModel.price &&
            !experiments.isAccessibilityPricingEnabled && (
              <OfferingDetails
                htmlTag={offeringViewModel.detailsHtmlTag}
                data-hook="tile-price"
              >
                {offeringViewModel.price}
              </OfferingDetails>
            )}
          {offeringViewModel.price &&
            experiments.isAccessibilityPricingEnabled && (
              <div>
                <div className={classes.srOnly}>
                  {offeringViewModel.priceAriaLabel}
                </div>
                <OfferingDetails
                  data-hook="tile-price"
                  htmlTag={offeringViewModel.detailsHtmlTag}
                  ariaHidden={true}
                >
                  {offeringViewModel.price}
                </OfferingDetails>
              </div>
            )}
        </div>
        {offeringViewModel.action.isVisible && (
          <div className={classes.column}>
            <OfferingCTA
              onClick={this.actionButtonClickHandler}
              dataHook="tile-button"
              large={offeringViewModel.action.isLarge}
              secondary={offeringViewModel.action.isSecondary}
              theme={offeringViewModel.action.type}
            >
              {offeringViewModel.action.text}
            </OfferingCTA>
          </div>
        )}
      </div>
    );
  }

  private renderVerticalLayoutInfo() {
    const { offeringViewModel, runningEnvironment, experiments } = this.props;
    const { isMobile, locale, isSSR } = runningEnvironment;

    return (
      <div
        data-hook="info-container"
        className={st(classes.verticalLayout, {
          alignment: offeringViewModel.textAlignment,
          verticalAlignContent: offeringViewModel.verticalAlignInfoContent,
          isMobile,
          stickButtonToBottom:
            offeringViewModel.layout === OfferingListLayoutOptions.GRID,
        })}
      >
        <div>
          {offeringViewModel.onlineIndication.isVisible && (
            <div className={classes.badgeWrapper}>
              <OfferingOnlineIndication data-hook="online-badge">
                {offeringViewModel.onlineIndication.text}
              </OfferingOnlineIndication>
            </div>
          )}
          <OfferingName
            data-hook="tile-title"
            url={offeringViewModel.fullUrl}
            onClick={this.offeringNameClickHandler}
            htmlTag={offeringViewModel.titleHtmlTag}
          >
            {offeringViewModel.title}
          </OfferingName>
          {offeringViewModel.tagLine && (
            <OfferingTagLine
              data-hook="tile-tagline"
              htmlTag={offeringViewModel.tagLineHtmlTag}
            >
              {offeringViewModel.tagLine}
            </OfferingTagLine>
          )}
          {offeringViewModel.moreInfoLabel.isVisible && (
            <OfferingMoreInfoLabel
              onClick={this.offeringMoreInfoClickHandler}
              dataHook="tile-more-info"
            >
              {offeringViewModel.moreInfoLabel.text}
            </OfferingMoreInfoLabel>
          )}
          {offeringViewModel.dividerVisibility && (
            <Divider
              data-hook="tile-divider"
              className={classes.divider}
              {...{ tabIndex: -1 }}
            />
          )}
          <section
            aria-labelledby="offering-info-aria-section-title"
            role="group"
          >
            <span
              style={{ display: 'none' }}
              id="offering-info-aria-section-title"
            >
              {offeringViewModel.offeringInfoAriaTitle}
            </span>
            {offeringViewModel.days && (
              <OfferingDetails
                data-hook="tile-days"
                htmlTag={offeringViewModel.detailsHtmlTag}
              >
                {offeringViewModel.days}
              </OfferingDetails>
            )}
            {offeringViewModel.startDate && (
              <OfferingDetails
                data-hook="tile-start-date"
                htmlTag={offeringViewModel.detailsHtmlTag}
              >
                {experiments.isUseIntlToFormatCourseDurationEnabled &&
                locale !== 'en' &&
                isSSR ? (
                  <>&nbsp;</>
                ) : (
                  offeringViewModel.startDate
                )}
              </OfferingDetails>
            )}
            {offeringViewModel.duration && (
              <OfferingDetails
                data-hook="tile-duration"
                htmlTag={offeringViewModel.detailsHtmlTag}
              >
                {offeringViewModel.duration}
              </OfferingDetails>
            )}
            {offeringViewModel.price &&
              !experiments.isAccessibilityPricingEnabled && (
                <OfferingDetails
                  data-hook="tile-price"
                  htmlTag={offeringViewModel.detailsHtmlTag}
                >
                  {offeringViewModel.price}
                </OfferingDetails>
              )}
            {offeringViewModel.price &&
              experiments.isAccessibilityPricingEnabled && (
                <div>
                  <div className={classes.srOnly}>
                    {offeringViewModel.priceAriaLabel}
                  </div>
                  <OfferingDetails
                    data-hook="tile-price"
                    ariaHidden={true}
                    htmlTag={offeringViewModel.detailsHtmlTag}
                  >
                    {offeringViewModel.price}
                  </OfferingDetails>
                </div>
              )}
          </section>
        </div>
        {offeringViewModel.action.isVisible && (
          <OfferingCTA
            onClick={this.actionButtonClickHandler}
            dataHook="tile-button"
            large={offeringViewModel.action.isLarge}
            secondary={offeringViewModel.action.isSecondary}
            theme={offeringViewModel.action.type}
          >
            {offeringViewModel.action.text}
          </OfferingCTA>
        )}
      </div>
    );
  }

  render() {
    return this.isStripLayout()
      ? this.renderHorizontalLayoutInfo()
      : this.renderVerticalLayoutInfo();
  }
}

export default withRunningEnvironmentContext(
  withExperimentsContext(
    withOfferingCallbacksContext(withBiLoggerContext(OfferingInfo)),
  ),
);
